


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {Image} from 'element-ui'

@Component({
  components: {
    [Image.name]: Image
  }
})
export default class CourseClassMaterialAnnex extends Vue {
  @Prop()
  data!: any
  imgs: Array<any> = []

  created() {
    this.getAllImage()
  }

  getAllImage() {
    let annex = this.data.annex
    let maxLen = annex.image_size
    let path = annex.image_base_path
    let ext = annex.image_ext
    let items = []
    for (let i = this.imgs.length; i < maxLen; i++) {
      let p = path + (i + 1) + ext
      items.push(p)
    }
    this.imgs = items
  }
}
