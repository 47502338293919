







import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import UeditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
import { getMaterial } from '@/modules/course/api'
import annex from './annex.vue'

  @Component({
    components: {
      annex,
      UeditorDisplay
    }
  })
export default class CourseClassMaterial extends Vue {
    @Prop() materials!: any
    materialDetail: any = {}

    @Watch('materials', {immediate: true})
    onMaterialsChanged() {
      this.materialDetail = {}
      if (this.materials.length > 0) {
        this.getDetail()
      }
    }

    getDetail() {
      let {course_id: courseId, id: mId} = this.materials[0]
      return getMaterial({
        course_id: courseId,
        material_id: mId
      }).then(e => {
        this.materialDetail = e
      })
    }
}
